<template>
<div>
  <div class="container-fluid header-sub " style="">
    <div class="container-standar h-100 "><div class="breadcumb-container"></div></div>
  </div>
  <div>
    <div class="container-standar text-center ">
      <div class="dk-container card shadow pl-30 pr-30">
      <div class="contact-image">
        <img src="@/assets/images/real-travel.png" alt="real-travel" />
      </div>
      <h3 class="text-primary text-center font-weight-6 font-18">
          <img class="width-15 margin-auto" src="@/assets/images/name-logo.png" alt="real-travel" />
      </h3>
        <span class="d-block font-30 text-primary  mt-10 font-weight-7 ">PELUANG USAHA</span>
        <span
                class="d-inline-block font-18 font-mobile-18 font-weight-7 text-primary width-100"
              >SOLUSI TERBAIK BAGI ANDA YANG INGIN PENGHASILAN DARI USAHA YANG BERNILAI IBADAH</span>
        <div class="ff-doa mt-2 mb-10 pad-10 pad-mobile-20">
          <div class="row">
            
            <div class="col-md-12 text-center">  
              <p class="mt-2 text-center"><span class="d-inline-block font-16 font-mobile-12">Apakah Anda Mengalami Salah Satu Hal Di Bawah Ini ?</span><br><span class="d-inline-block font-16 font-mobile-12">Belum Punya Modal Lebih Untuk Memulai Usaha ?</span><br><span class="d-inline-block font-16 font-mobile-12">Sudah Punya Modal Lebih, Tapi Bingung Memulai Dari Mana ?</span><br><span class="d-inline-block font-16 font-mobile-12">Sudah Mulai Belajar Dan Praktik, Tapi Hasilnya Belum Memuaskan ?</span><br><span class="d-inline-block font-16 font-mobile-12">Ingin Berangkat Umroh Tapi Belum Cukup Dana ?</span><br></p>
              <p class="mt-10">Jika Iya, Maka Agent Real Travel Adalah Solusinya</p>

              <p class="mt-10"><b>REALTRAVEL</b> adalah sebuah Sistem Platform dari <b>PT. Pandi Kencana Murni (Pakem Tours)</b> Travel Penyelenggara Umroh dan Haji khusus. sebuah Inovasi teknologi digital Umroh yang berbasis web dan applikasi android berisi pelayanan satu akses pemesanan paket umrah dari awal sampai akhir. Dari mulai rencana sampai nanti akhirnya berangkat itu ada dalam satu platform.</p>

              <p class="mt-10">Dengan <b>REALTRAVEL</b>, Anda bisa menjual paket umroh dengan mudah baik melalui website maupun melalui aplikasi android dan mendapatkan banyak keuntungan.</p>

              <p class="mt-10"><b>BEBERAPA KEUNTUNGAN MENJADI AGENT REAL TRAVEL</b></p>

              <p class="mt-5 text-center"><span class="d-inline-block font-16 font-mobile-12"><i class="fa fa-star text-danger"></i> Berhak untuk menjual paket umroh melalui website dan aplikasi</span><br><span class="d-inline-block font-16 font-mobile-12"><i class="fa fa-star text-danger"></i> Mendapatkan komisi penjualan paket umroh</span><br><span class="d-inline-block font-16 font-mobile-12"><i class="fa fa-star text-danger"></i> Support training pemasaran dan penjualan baik online maupun ofline</span><br><span class="d-inline-block font-16 font-mobile-12"><i class="fa fa-star text-danger"></i> Support tools pemasaran dan penjualan</span><br><span class="d-inline-block font-16 font-mobile-12"><i class="fa fa-star text-danger"></i> Reward Umroh Gratis</span></p>

              <p class="mt-10"><b>SEGERA MANFAATKAN KESEMPATAN INI !</b></p>

              <p class="mt-10">Bergabung bersama <b>RealTravel</b> sebagai penyambung wasilah untuk jamaah yang ingin berangkat umroh</p>

              <p class="mt-10">Install Aplikasi  & Daftar Jadi Agent Sekarang !</p>

              <p class="mt-10 mb-30"><a href="/register" class="btn bg-primary advance-search text-white font-weight-6 pt-2 mt-4 pb-2 width-40 font-16"><span> Daftar Sekarang </span></a></p>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="container-fluid mb-10 mb-res-10 bg-customise-content pt-10 pb-1">
      <section class="mb-50 pt-15">
        <div class="container">
          <div class="row">
            <div class="col col-md-12 text-center mb-20 tiitle-ex">
              <span class="d-block text-white font-28 font-weight-7 roboto">PAKET UMROH</span>
              <span class="d-block text-white font-20">Paket Umroh Terbaik Untuk Anda</span>
            </div>
            <div class="row">
              <package-umroh></package-umroh>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="container-fluid mb-20">
       <div class="col-md-12 mt-10 mb-10 text-center">
        <span class="d-block text-primary font-28 font-weight-7 roboto">PAKET TOUR</span>
        <span class="d-block text-grey font-20">Pilihan Destinasi Wisata Terbaik</span>
      </div>
      <div class="row">
      <beritaList></beritaList>
      </div>
                
    </div>

  </div>
</div>
</template>

<script>
import PackageUmroh from "@/views/components/package-umroh.vue";
import beritaList from "@/views/components/berita-list";
export default {
  components: {
    PackageUmroh,
    beritaList
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("SET_BG", true);
  }
};
</script>